import React, { Component } from 'react'
import axios from 'axios'
import { fetchTrips } from '../../helpers/constants'

import Navbar from '../global/Navbar'
import TripCard from './TripCard'

import style from './PickTrip.module.css'
import mainStyle from '../../styles/main.module.css'

import art from '../../assets/svg/pickTrip.svg'

export default class PickTrip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resolved: false,
      all: [],
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    try {
      const all = await fetchTrips()
      console.log(all)
      this.setState({ resolved: true, all })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
      <div className={style.wrapper}>
        <div className={style.background}>
          <img className={style.svg} src={art} alt="background art" />
        </div>

        <div className={style.foreground}>
          <Navbar style="dark" />
          <h1 className={`${mainStyle.title} ${mainStyle.paddingLeft}`}>
            Mes carnets
          </h1>

          <div className={style.cardWrapper}>
            {this.state.resolved
              ? this.state.all.map(trip => {
                  return (
                    <TripCard
                      key={trip.id}
                      title={trip.title}
                      startDate={trip.created_at}
                      id={trip.id}
                    />
                  )
                })
              : null}
          </div>
        </div>
      </div>
    )
  }
}
