import React, { Component } from 'react'
import axios from 'axios'

import { backendUrl } from '../../../helpers/constants'

import playIcon from '../../../assets/svg/icons/play.svg'
import pauseIcon from '../../../assets/svg/icons/pause.svg'

import style from './AudioCard.module.css'

export default class AudioCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      playTime: 0,
    }
    this.audio = new Audio()
    this.addListeners()
  }

  play() {
    this.setState({ playing: true })
    this.audio.src = `${backendUrl}/fetch/audio?tripId=${this.props.tripId}&id=${this.props.id}&ext=${this.props.extension}`
    this.audio.play()
  }

  stop() {
    this.setState({ playing: false })
    this.audio.pause()
    this.audio.currentTime = 0
  }

  addListeners() {
    this.audio.addEventListener('ended', () => {
      this.setState({ playing: false })
    })
    this.audio.addEventListener('timeupdate', () => {
      this.setState({ playTime: this.audio.currentTime })
    })
  }

  removeListeners() {
    this.audio.removeEventListener('ended')
    this.audio.removeEventListener('timeupdate')
  }

  formatTime(seconds) {
    let minutes = Math.floor(seconds / 60)
    minutes = minutes >= 10 ? minutes : '0' + minutes
    seconds = Math.floor(seconds % 60)
    seconds = seconds >= 10 ? seconds : '0' + Math.floor(seconds)
    return `${minutes}:${seconds}`
  }

  render() {
    const { playing, playTime } = this.state
    return (
      <div className={style.card}>
        <h3 className={style.location}>{this.props.locationName}</h3>
        <span className={style.date}>{this.props.date}</span>
        <div className={style.playWrapper}>
          <button
            onClick={() => (playing ? this.stop() : this.play())}
            className={style.playBtn}
          >
            {!playing ? (
              <img className={style.playBtnPause} src={playIcon} alt="play" />
            ) : (
              <img src={pauseIcon} alt="pause" />
            )}
          </button>

          <span className={style.playTime}>{this.formatTime(playTime)}</span>
        </div>
      </div>
    )
  }
}
