import React, { Component } from 'react';
import backdrop from '../../assets/svg/auth.svg'
import Navbar from '../global/Navbar'

import style from './ComingSoon.module.css'


export default class ComingSoon extends Component {
	render() {
		return (
			<div className={style.wrapper}>
				<Navbar style='dark'/>
				<img className={style.backdrop} src={backdrop} alt=''/>
				<h1 className={style.title}>Coming Soon</h1>
			</div>
		);
	}
}
