import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { backendUrl } from '../../helpers/constants'
import style from './Auth.module.css'
import mainStyle from '../../styles/main.module.css'

export default class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      secondPassword: '',
      loading: false,
      error: false,
      msg: null,
      finished: false,
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  storeCredentials(token) {
    localStorage.setItem('userToken', token)
  }

  submit = async event => {
    event.preventDefault()
    const { password, secondPassword } = this.state
    if (password && secondPassword && password === secondPassword) {
      this.setState({ loading: true })
      try {
        const res = await axios.post(`${backendUrl}/auth/reinit-password`, {
          code: window.location.search.slice(6),
          password,
        })
        if (res.data.status === 200) {
          this.setState({ error: false, finished: true })
        } else {
          this.setState({ error: true, msg: 'Erreur avec internet ?' })
        }
      } catch (error) {
        console.error(error)
      }
    } else
      this.setState({
        error: true,
        msg: 'Vos mots de passe ne coincident pas !',
      })
  }

  DisplayError = () => {
    if (this.state.error) {
      return <span className={style.error}>{this.state.msg}</span>
    } else {
      return null
    }
  }

  render() {
    if (this.state.finished) {
      return (
        <div className={style.mainWrapper}>
          <h1 className={style.title}>Réinitialisez votre mot de passe</h1>
          <p className={style.subbutton}>
            Vous venez de changer votre mot de passe ! Vous pouvez maintenant
            vous connecter sur le site ou sur l'application !
          </p>
          <button
            className={mainStyle.bigPink}
            onClick={() => this.props.history.push('/auth/signin')}
          >
            Retour au login !
          </button>
        </div>
      )
    } else {
      return (
        <div className={style.mainWrapper}>
          <span className={style.span}>Réinitialisez votre mot de passe</span>
          <form onSubmit={this.submit}>
            <div className={style.inputWrapper}>
              <input
                className={style.input}
                onChange={e => this.handleChange(e)}
                type="password"
                name="password"
                placeholder="Mot de passe"
              />
              <input
                className={style.input}
                onChange={e => this.handleChange(e)}
                type="password"
                name="secondPassword"
                placeholder="Encore une fois"
              />
              <this.DisplayError />
            </div>
            <div className={style.wrapper}>
              <input
                type="submit"
                className={mainStyle.bigPink}
                value="Valider"
              />
              <Link className={style.subbutton} to="/auth/signup">
                Vous n'avez pas encore de compte ?{' '}
                <span className={style.pinkText}>Inscrivez-vous</span>
              </Link>
            </div>
          </form>
        </div>
      )
    }
  }
}
