import React, { Component } from 'react'
import moment from 'moment/min/moment-with-locales'
import axios from 'axios'

import {
  backendUrl,
  getUserToken,
  fetchTripInfo,
  fetchTripNotes,
} from '../../helpers/constants'

import Navbar from '../global/Navbar'
import Mapbox from '../global/Mapbox'
import LoadingPage from '../global/LoadingPage'
import Spinner from '../global/Spinner'

import DayBox from './timeline/DayBox'
import MediaViewer from './timeline/MediaViewer'

import style from './TripDetails.module.css'
import noThumb from '../../assets/svg/noThumb.svg'

import book from '../../assets/svg/icons/book.svg'
import image from '../../assets/svg/icons/image.svg'
import micro from '../../assets/svg/icons/micro.svg'
import calendar from '../../assets/svg/icons/calendar.svg'
import search from '../../assets/svg/icons/search.svg'

export default class TripDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mapLoaded: false,
      retracted: false,
      thumb: null,
      tripId: null,
      info: null,
      filter: 'all',
      list: {},
      media: null,
      showViewer: false,
      viewIndex: 0,
      days: [],
      markers: [],
      region: null,
    }

    this.reload = this.reload.bind(this)
    this.openMediaViewer = this.openMediaViewer.bind(this)
    this.closeMediaViewer = this.closeMediaViewer.bind(this)
  }

  componentDidMount() {
    const tripId = this.getTripId()
    this.fetchThumbnail(tripId)
    this.fetchInfo(tripId)
    this.fetchList(tripId)
  }

  reload(filter) {
    console.log('reload', filter)
    const tripId = this.getTripId()
    this.fetchList(tripId, filter)
    this.fetchInfo(tripId)
  }

  filterList(filter) {
    this.setState({ filter })
    this.reload(filter)
  }

  getTripId() {
    const url = new URL(window.location.href)
    const tripId = url.searchParams.get('trip')
    this.setState({ tripId })
    return tripId
  }

  async fetchInfo(tripId) {
    const res = await fetchTripInfo(tripId)
    let { info, latestUpdate } = res.data
    console.log(latestUpdate)
    info.latestUpdate = latestUpdate
    this.setState({ info })
  }

  async fetchList(tripId, filter) {
    const list = await fetchTripNotes(tripId)
    this.renderList(list, filter)
    this.extractCoords(list, filter)
    return list
  }

  displayDate(date) {
    moment.locale('fr')
    const formatted = moment(date).format('dddd, Do MMMM YYYY')
    const capitalized = formatted[0].toUpperCase() + formatted.slice(1)
    return capitalized
  }

  extractCoords(list) {
    let markers = []
    for (let category in list) {
      list[category].forEach(item => {
        const { latitude, longitude, id } = item
        markers.push([Number(latitude), Number(longitude), id])
      })
    }

    this.createRegion(markers)
  }

  createRegion(markers) {
    const latitude = markers[0][0]
    const longitude = markers[0][1]
    const region = { latitude, longitude }
    this.setState({ markers, region })
  }

  async fetchThumbnail(tripId) {
    try {
      const token = await getUserToken()
      const res = await axios.post(`${backendUrl}/crea/trip/thumb`, {
        token,
        tripId,
      })
      const { base64 } = res.data
      if (base64) {
        this.setState({ thumb: base64 })
      }
    } catch (e) {
      console.error(e)
    }
  }

  renderList(list, filter) {
    if (list.text && list.media && list.audio) {
      //prepare media viewer
      this.extractMedia(list.media)

      //differenciate by type
      for (let noteType in list) {
        list[noteType].forEach(note => {
          note.type = noteType
        })
      }
      let categorized
      switch (filter) {
        case 'media': {
          console.log('media')
          categorized = list.media
          break
        }
        case 'audio': {
          console.log('audio')
          categorized = list.audio
          break
        }
        case 'text': {
          console.log('text')
          categorized = list.text
          break
        }
        default: {
          console.log('all')
          categorized = list.text.concat(list.media, list.audio)
        }
      }
      //sort by date
      //for each day, sort by time
      const sorted = categorized
        .sort((a, b) => {
          const dateA = new Date(a.created_at)
          const dateB = new Date(b.created_at)
          if (dateA > dateB) return 1
          else if (dateA < dateB) return -1
          else return 0
        })
        .reverse()
      //seperate by day
      let days = []
      let currentDay = {
        date: '',
        items: [],
      }
      let index = 0
      if (sorted.length === 1) {
        const date = this.extractDateFromTs(sorted[0].created_at)
        const day = {
          date,
          items: [sorted[0]],
        }
        days.push({ ...day })
      } else {
        sorted.forEach(item => {
          const itemDate = this.extractDateFromTs(item.created_at)
          //first
          if (index === 0) {
            currentDay.date = itemDate
            currentDay.items.push(item)
          }
          //last
          else if (index === sorted.length - 1) {
            if (currentDay.date === itemDate) {
              //add to current day, push to days
              currentDay.items.push(item)
              days.push({ ...currentDay })
            } else {
              const lastDay = {
                date: itemDate,
                items: [item],
              }
              days.push({ ...lastDay })
            }
          }
          //in between
          else {
            if (currentDay.date === itemDate) {
              //if same, push to day
              currentDay.items.push(item)
            } else {
              //else if different, push current day to days array
              days.push({ ...currentDay })
              // console.log(days)

              //and reset day with item date
              currentDay.date = itemDate
              currentDay.items = [item]
            }
          }
          // console.log(currentDay)
          index++
        })
      }
      this.setState({ days, list })
    }
  }

  extractDateFromTs(ts) {
    const split = ts.split('T'),
      date = split[0]
    return date
  }

  extractMedia(media) {
    const minified = media.map(item => ({
      id: item.id,
      tripId: item.trip_id,
      extension: item.extension,
    }))
    console.log(minified)
    this.setState({ media: minified })
  }

  openMediaViewer(id, tripId, extension) {
    //find index
    const selected = { id, tripId, extension }
    console.log(selected, this.state.media)

    this.state.media.forEach(item => {
      if (selected.id === item.id) {
        const viewIndex = this.state.media.indexOf(item)
        console.log('found selected view index', viewIndex)
        this.setState({ showViewer: true, viewIndex })
      }
    })
  }

  closeMediaViewer() {
    this.setState({ showViewer: false })
  }

  formatDate(ts) {
    const split = ts.split('T')
    const time = split[1].slice(0, 5),
      year = split[0].slice(0, 4),
      month = split[0].slice(5, 7),
      day = split[0].slice(8, 10),
      final = `${day}.${month}.${year} - ${time}`

    return final
  }

  renderMapbox = () => {
    const { markers, region } = this.state
    console.log(region)
    return (
      <Mapbox
        markers={markers}
        latitude={region.latitude}
        longitude={region.longitude}
        zoom={5}
      />
    )
  }

  render() {
    if (this.state.info) {
      const { region, markers } = this.state
      console.log(this.state.days)
      return (
        <div className={style.wrapper}>
          <Navbar style="light" />

          <div className={style.banner}>
            <div className={style.imgWrapper}>
              {this.state.thumb ? (
                <img src={this.state.thumb} alt="trip banner" />
              ) : (
                <Spinner />
              )}
            </div>

            <h1 className={style.tripTitle}>{this.state.info.title}</h1>
            {this.state.days[0] && this.state.info ? (
              <span className={style.tripDate}>
                {this.displayDate(this.state.info.created_at)} -{' '}
                {this.displayDate(this.state.days[0].date)}
              </span>
            ) : null}
          </div>

          <div className={style.menuWrapper}>
            <div className={style.pseudoLeft}></div>

            <div className={style.menuPill}>
              <button onClick={() => this.filterList('all')}>
                <img src={book} alt="carnet" />
                Carnet
              </button>

              <button onClick={() => this.filterList('media')}>
                <img src={image} alt="carnet" />
                Album
              </button>

              <button onClick={() => this.filterList('audio')}>
                <img src={micro} alt="carnet" />
                Audio
              </button>

              <button onClick={() => this.filterList('text')}>
                <img src={calendar} alt="carnet" />
                Notes
              </button>

              <button>
                <img className={style.searchIcon} src={search} alt="carnet" />
              </button>
            </div>

            <button className={style.printBtn}>Imprimer</button>
          </div>

          <div className={style.scrollWrapper}>
            <div className={style.mapWrapper}>
              {region ? <this.renderMapbox /> : null}
            </div>
            <div className={style.progressWrapper}></div>

            <div className={style.timelineWrapper}>
              {this.state.days.map(day => {
                return (
                  <DayBox
                    key={day.date}
                    date={day.date}
                    items={day.items}
                    reload={this.reload}
                    openMediaViewer={this.openMediaViewer}
                  />
                )
              })}
            </div>
          </div>

          {this.state.media ? (
            <MediaViewer
              media={this.state.media}
              initIndex={this.state.viewIndex}
              close={this.closeMediaViewer}
              visible={this.state.showViewer}
              reload={this.reload}
            />
          ) : null}
        </div>
      )
    } else return <LoadingPage />
  }
}
