import React, { Component } from 'react'
import autosize from 'autosize'

import Mapbox from '../../global/Mapbox'
import {
  updateNoteText,
  updateNoteColor,
  deleteNote,
} from '../../../helpers/constants'

import style from './Details.module.css'
import mainStyle from '../../../styles/main.module.css'
import closeIcon from '../../../assets/svg/icons/close.svg'
import deleteIcon from '../../../assets/svg/icons/trash.svg'
import shareIcon from '../../../assets/svg/icons/share.svg'
import editIcon from '../../../assets/svg/icons/edit.svg'

import markerIcon from '../../../assets/svg/icons/marker.svg'

const colors = [
  'white',
  'yellow',
  'red',
  'blue',
  'purple',
  'cyan',
  'pink',
  'orange',
  'green',
  'lavender',
]

export default class TextDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      edit: false,
      editColor: false,
      deleteConfirmation: false,
      deleted: false,
      updated: false,
      text: this.props.text,
      color: this.props.color,
    }
  }

  componentDidMount() {
    this.setState({ show: this.props.show })
  }

  componentWillUpdate(nextProps, nextState) {
    console.log('modal update', this.props.show)
    if (nextProps.show !== this.props.show) {
      console.log(nextProps.show)
      this.setState({ show: nextProps.show })
    }
  }

  close(event) {
    console.log(event)
    if (event) {
      event.stopPropagation()
    }
    const { closeModal } = this.props
    // this.setState({show: false})
    closeModal(false)
  }

  toggleEdit() {
    this.setState({ edit: !this.state.edit, editColor: !this.state.editColor })
  }

  handleEdit(text) {
    console.log(text)
    autosize(this.textarea)
    this.setState({ text })
  }

  async deleteNote() {
    if (this.state.deleteConfirmation) {
      const deletionComplete = await deleteNote(this.props.id, 'text')
      console.log(deletionComplete)
      if (deletionComplete) {
        console.log('deletion success')
        this.setState({ deleted: true })
        this.props.reload()
      } else {
        console.log('deletion error')
      }
    }
  }

  async submitChanges() {
    //check for changes in text and / or color
    if (!this.state.deleted) {
      let changes = false
      if (this.state.text !== this.props.color) {
        const { note_text } = await updateNoteText(
          this.props.id,
          this.state.text
        )
        changes = true
        console.log(note_text)
      }

      if (this.state.color !== this.props.color) {
        const { color } = await updateNoteColor(this.props.id, this.state.color)
        changes = true
        console.log(color)
      }

      if (changes) {
        this.props.reload()
        this.close()
      }
    }
  }

  changeColor(color) {
    this.setState({ color })
  }

  renderColorPicker() {
    return (
      <div className={style.colorPicker}>
        {colors.map(color => (
          <button
            onClick={() => this.changeColor(color)}
            className={`${style.colorBtn} ${
              color === this.state.color ? style.selectedColor : null
            }`}
          >
            <div className={color}></div>
          </button>
        ))}
      </div>
    )
  }

  render() {
    console.log(this.props)
    let { latitude, longitude, date, locationName } = this.props
    const {
      edit,
      show,
      text,
      color,
      deleteConfirmation,
      editColor,
    } = this.state

    latitude = Number(latitude)
    longitude = Number(longitude)

    if (show) {
      return (
        <div ref={modal => (this.modal = modal)} className={style.wrapper}>
          <div className={`${style.topBorder} ${editColor ? null : color}`}>
            <button
              className={style.closeBtn}
              onClick={event => this.close(event)}
            >
              <img
                className={`${editColor ? style.inverted : null}`}
                src={closeIcon}
                alt="Close Window"
              />
            </button>

            {deleteConfirmation ? (
              <div className={style.deleteConfirmation}>
                <span>Voulez-vous vraiment supprimer cette note ?</span>
                <button
                  className={style.stopDelete}
                  onClick={() => this.setState({ deleteConfirmation: false })}
                >
                  Non
                </button>
                <button
                  className={style.confirmDelete}
                  onClick={() => this.deleteNote()}
                >
                  Oui
                </button>
              </div>
            ) : null}

            {editColor ? this.renderColorPicker() : null}
          </div>

          <div className={style.actionWrapper}>
            <button onClick={() => this.setState({ deleteConfirmation: true })}>
              <img src={deleteIcon} alt="Delete" />
            </button>
            <button onClick={() => this.toggleEditColor()}>
              <img src={shareIcon} alt="Share" />
            </button>
            <button
              className={style.inverted}
              onClick={() => this.toggleEdit()}
            >
              <img src={editIcon} alt="Edit" />
            </button>
          </div>

          <div className={style.contentWrapper}>
            <div className={style.textWrapper}>
              <div className={`${style.background} ${color}`}></div>
              <h4 className={style.location}>
                <img className={style.markerIcon} src={markerIcon} alt=" " />
                {locationName}
              </h4>

              <span className={style.date}>{date}</span>

              {!edit ? (
                <p className={style.text}>{text}</p>
              ) : (
                <textarea
                  className={style.editText}
                  name="noteText"
                  value={text}
                  ref={textarea => (this.textarea = textarea)}
                  onChange={e => this.handleEdit(e.target.value)}
                />
              )}
            </div>

            <div className={style.mapWrapper}>
              <Mapbox
                markers={[[latitude, longitude]]}
                latitude={latitude}
                longitude={longitude}
                zoom={10}
              />
            </div>

            <div className={style.validateWrapper}>
              <button
                onClick={() => this.submitChanges()}
                className={mainStyle.smallPink}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )
    } else return null
  }
}
