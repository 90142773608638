import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Frame from './components/auth/Frame'
import PickTrip from './components/trips/PickTrip'
import Profile from './components/profile/Profile'
import TripDetails from './components/trips/TripDetails'
import CheckLogin from './components/auth/CheckLogin'
import ComingSoon from './components/print/ComingSoon'

import './styles/fonts.css'
import './styles/active.css'
import './styles/colors.css'
import './styles/switch.css'
import './styles/loading.css'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={CheckLogin} />
        <Route path="/auth" component={Frame} />
        <Route path="/trip/details" component={TripDetails} />
        <Route exact path="/trip" component={PickTrip} />
        <Route path="/profile" component={Profile} />
        <Route path="/print" component={ComingSoon} />
      </Switch>
    </div>
  )
}

export default App
