import React, { Component } from 'react'

import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'

import {
  backendUrl,
  setUserToken,
  setPersistent,
} from '../../helpers/constants'
import style from './Auth.module.css'
import mainStyle from '../../styles/main.module.css'

export default class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      name: '',
      loading: false,
      persistent: false,
      error: false,
      msg: null,
      redirect: false,
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  async submit() {
    const { email, password, name, persistent } = this.state
    if (name && email && password) {
      this.setState({ loading: true })
      try {
        const res = await axios.post(`${backendUrl}/auth/signup`, {
          email,
          name,
          password,
        })
        console.log(res)
        const { message, token } = res.data
        console.log(message, token)

        if (!token) {
          this.setState({ error: true, msg: message })
          return
        }

        if (token) {
          setUserToken(token)
        }

        if (persistent) {
          setPersistent(true)
        } else {
          setPersistent(false)
        }

        this.setState({ error: false, redirect: true })
      } catch (e) {
        console.error(e)
      }
    } else
      this.setState({
        error: true,
        msg: 'Merci de renseigner votre e-mail et votre mot de passe.',
      })
  }

  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to="/trip" />
    }
  }

  render() {
    return (
      <div className={style.mainWrapper}>
        {this.renderRedirect()}
        <h1 className={style.title}>Bienvenue !</h1>
        <span className={style.span}>S'inscrire</span>

        <div className={style.inputWrapper}>
          <input
            className={style.input}
            onChange={e => this.handleChange(e)}
            type="text"
            name="name"
            placeholder="Prénom"
          />
          <input
            className={style.input}
            onChange={e => this.handleChange(e)}
            type="email"
            name="email"
            placeholder="E-mail"
          />
          <input
            className={style.input}
            onChange={e => this.handleChange(e)}
            type="password"
            name="password"
            placeholder="Mot de passe"
          />

          {this.state.error ? (
            <span className={style.error}>{this.state.msg}</span>
          ) : null}

          <label className={mainStyle.smallText}>
            <input
              type="checkbox"
              name="persistent"
              onChange={e => this.handleChange(e)}
            />
            Se souvenir de moi
          </label>
        </div>

        <div className={style.warning}>
          <span>
            En vous inscrivant, vous acceptez nos <br />{' '}
            <a
              href="http://localhost:3000/conditions"
              className={style.conditions}
            >
              conditions d'utilisation et politique de confidentialité.
            </a>
          </span>
        </div>

        <div className={style.wrapper}>
          <button onClick={e => this.submit()} className={mainStyle.bigPink}>
            Valider
          </button>
          <Link className={style.subbutton} to="/auth/signin">
            J'ai déjà un compte
          </Link>
        </div>
      </div>
    )
  }
}
