import React, { Component } from 'react'
import moment from 'moment/min/moment-with-locales'

import style from './DayBox.module.css'

import MediaCard from './MediaCard'
import TextCard from './TextCard'
import AudioCard from './AudioCard'

export default class DayBox extends Component {
  switchCard(item) {
    switch (item.type) {
      case 'text':
        return this.renderTextCard(item)

      case 'media':
        return this.renderMediaCard(item)

      case 'audio':
        return this.renderAudioCard(item)

      default:
        console.log('unknown card type')
    }
  }

  createColumns() {
    const { items } = this.props

    const col1 = [],
      col2 = []
    let index = 0

    items.forEach(item => {
      if (index % 2 === 0) {
        col1.push(item)
      } else {
        col2.push(item)
      }
      index++
    })

    return (
      <div className={style.columnWrapper}>
        <div className={`${style.left} ${style.column}`}>
          {col1.map(item => this.switchCard(item))}
        </div>

        <div className={`${style.right} ${style.column}`}>
          {col2.map(item => this.switchCard(item))}
        </div>
      </div>
    )
  }

  createCards() {
    const { items } = this.props
    return this.createColumns(items)
  }

  displayDate(date) {
    moment.locale('fr')
    const formatted = moment(date).format('dddd, Do MMMM YYYY')
    const capitalized = formatted[0].toUpperCase() + formatted.slice(1)
    return capitalized
  }

  renderTextCard(item) {
    return (
      <TextCard
        style={style.card}
        reload={this.props.reload}
        key={item.id}
        id={item.id}
        onPress={() =>
          this.props.navigation.navigate('TextDetails', { note: item })
        }
        locationName={item.location_name}
        date={this.formatDate(item.created_at)}
        latitude={item.latitude}
        longitude={item.longitude}
        text={item.note_text}
        color={item.color}
      />
    )
  }

  renderAudioCard(item) {
    return (
      <AudioCard
        reload={this.props.reload}
        key={item.id}
        id={item.id}
        tripId={item.trip_id}
        locationName={item.location_name}
        extension={item.extension}
        date={this.formatDate(item.created_at)}
        latitude={item.latitude}
        longitude={item.longitude}
        color={item.color}
      />
    )
  }

  renderMediaCard(item) {
    return (
      <MediaCard
        reload={this.props.reload}
        key={item.id}
        id={item.id}
        tripId={item.trip_id}
        extension={item.extension}
        latitude={item.latitude}
        longitude={item.longitude}
        openMediaViewer={this.props.openMediaViewer}
        date={this.formatDate(item.created_at)}
      />
    )
  }

  formatDate(ts) {
    const split = ts.split('T')
    const time = split[1].slice(0, 5),
      year = split[0].slice(0, 4),
      month = split[0].slice(5, 7),
      day = split[0].slice(8, 10),
      final = `${day}.${month}.${year} - ${time}`

    return final
  }

  render() {
    return (
      <div className={style.wrapper}>
        <h3 className={style.date}>{this.displayDate(this.props.date)}</h3>
        <div>{this.createCards()}</div>
      </div>
    )
  }
}
