import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { fetchMedia } from '../../../helpers/constants'

import style from './MediaCard.module.css'
import playIcon from '../../../assets/svg/icons/play.svg'

export default class MediaCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      media: null,
      portrait: true,
      evaluatedRotation: false,
      details: false,
    }
    this.open = this.open.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  landscapeOrPortrait() {
    const { evaluatedRotation } = this.state

    if (this.media && !evaluatedRotation) {
      const { scrollHeight, scrollWidth } = this.media

      if (scrollHeight > scrollWidth) {
        this.setState({ portrait: true, evaluatedRotation: true })
      } else {
        this.setState({ portrait: false, evaluatedRotation: true })
      }
    }
  }

  open() {
    const { id, tripId, extension } = this.props
    this.props.openMediaViewer(id, tripId, extension)
  }

  async fetch() {
    const base64 = await fetchMedia(
      this.props.id,
      this.props.tripId,
      this.props.extension
    )
    this.setState({ media: base64 })
    this.landscapeOrPortrait()
  }

  render() {
    return (
      <div
        onClick={() => this.open()}
        className={`${style.card} ${
          this.state.portrait ? style.portrait : style.landscape
        }`}
      >
        {this.props.extension === 'jpg' ? (
          <img
            ref={image => (this.media = image)}
            src={this.state.media}
            alt="media"
            className={style.media}
          />
        ) : (
          <div className={style.videoWrapper}>
            <button className={style.playBtn}>
              <img src={playIcon} alt="play video" />
            </button>

            <video
              ref={video => (this.media = video)}
              src={this.state.media}
              className={style.media}
              muted
            />
          </div>
        )}
      </div>
    )
  }
}
