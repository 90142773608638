import React, { Component } from 'react'
import Switch from 'rc-switch'

import LogoutBtn from '../global/LogoutBtn'
import Navbar from '../global/Navbar'
import ProfilePic from './ProfilePic'

import {
  getUserInfo,
  updateUserInfo,
  getUserSettings,
  changeUserSettings,
  changePwd,
} from '../../helpers/constants'

import style from './Profile.module.css'
import mainStyle from '../../styles/main.module.css'
import noPic from '../../assets/svg/icons/profile_round.svg'
import edit from '../../assets/svg/icons/edit.svg'
import backdrop from '../../assets/svg/backdrop.svg'

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile_picture: null,
      name: '',
      email: '',
      city: '',
      age: null,
      bio: '',
      newsletter: false,
      notifications: true,

      password: {
        oldPwd: null,
        newPwd: null,
      },
    }
  }

  componentDidMount() {
    this.fetchInfo()
  }

  async submitChanges() {
    const {
      name,
      email,
      city,
      age,
      bio,
      notifications,
      newsletter,
    } = this.state
    const { oldPwd, newPwd } = this.state.password
    console.log(name, email, city, age, bio)
    const info = await updateUserInfo(name, email, age, bio, city)
    const settings = await changeUserSettings(notifications, newsletter)
    const password = await changePwd(oldPwd, newPwd)
  }

  async fetchInfo() {
    const info = await getUserInfo()
    const settings = await getUserSettings()
    console.log(info)

    for (let key in info) {
      this.setState({ [key]: info[key] })
    }

    const { profile_picture } = info
    console.log(profile_picture)
    this.setState({ profile_picture })

    for (let key in settings) {
      this.setState({ [key]: settings[key] })
    }
  }

  handleChange(e) {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSwich(status, name) {
    this.setState({ [name]: status })
  }

  handleChangePwd(e) {
    e.preventDefault()
    const buffer = this.state.password
    const { name, value } = e.target
    buffer[name] = value
    this.setState({ password: buffer })
  }

  render() {
    const { name, email, city, age, bio, profile_picture } = this.state

    return (
      <div className={style.wrapper}>
        <div className={style.background}>
          <img src={backdrop} alt="" />
        </div>

        <div className={style.foreground}>
          <Navbar style="dark" />

          <ProfilePic pic={profile_picture} />

          <div className={style.profileWrapper}>
            <div className={style.column}>
              <div className={`${style.info} ${style.card}`}>
                <h2 className={style.title}>Informations personnelles</h2>

                <label className={style.input}>
                  Prénom
                  <input
                    type="text"
                    value={name}
                    name="name"
                    onChange={e => this.handleChange(e)}
                  />
                </label>

                <label className={style.input}>
                  E-mail
                  <input
                    type="email"
                    value={email}
                    name="email"
                    onChange={e => this.handleChange(e)}
                  />
                </label>

                <label className={style.input}>
                  Ville
                  <input
                    type="text"
                    value={city}
                    name="city"
                    onChange={e => this.handleChange(e)}
                  />
                </label>

                <label className={style.input}>
                  Âge
                  <input
                    type="number"
                    value={Number(age)}
                    name="age"
                    onChange={e => this.handleChange(e)}
                  />
                </label>

                <label className={style.input}>
                  Biographie
                  <textarea
                    type="text"
                    value={bio}
                    name="bio"
                    onChange={e => this.handleChange(e)}
                  ></textarea>
                </label>
              </div>
              <LogoutBtn />
            </div>

            <div className={style.column}>
              <div className={`${style.settings} ${style.card}`}>
                <h2 className={style.title}>Notifications</h2>
                <label className={`${style.input} ${style.middleToggle}`}>
                  Recevoir des notifications
                  <br />
                  sur mon smartphone
                  <Switch
                    checked={this.state.notifications}
                    disabled={false}
                    onChange={e => this.handleSwich(e, 'notifications')}
                  />
                </label>

                <label className={style.toggle}>
                  Recevoir la newsletter
                  <Switch
                    checked={this.state.newsletter}
                    disabled={false}
                    onChange={e => this.handleSwich(e, 'newsletter')}
                  />
                </label>
              </div>

              <div className={`${style.password} ${style.card}`}>
                <h2 className={style.title}>Mot de passe</h2>

                <label className={style.input}>
                  Mot de passe actuel
                  <input
                    type="password"
                    name="oldPwd"
                    onChange={e => this.handleChangePwd(e)}
                  />
                </label>

                <label className={style.input}>
                  Nouveau mot de passe
                  <input
                    type="password"
                    name="newPwd"
                    onChange={e => this.handleChangePwd(e)}
                  />
                </label>

                <button
                  onClick={() => this.submitChanges()}
                  className={`${mainStyle.smallPink} ${style.submit}`}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
