import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { deleteUserToken } from '../../helpers/constants'

import style from './LogoutBtn.module.css'

export default class LogoutBtn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirect: false,
    }
  }

  async logout() {
    await deleteUserToken()
    this.setState({ redirect: true })
  }

  render() {
    return (
      <button className={style.btn} onClick={() => this.logout()}>
        Se déconnecter
        {this.state.redirect ? <Redirect to="/auth/signin" /> : null}
      </button>
    )
  }
}
