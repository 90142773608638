import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import Spinner from '../global/Spinner'

import {
  backendUrl,
  getUserToken,
  fetchTripInfo,
} from '../../helpers/constants'

import style from './TripCard.module.css'

import noThumb from '../../assets/svg/noThumb.svg'

const dateOptions = {
  weekday: 'numeric',
}

export default class TripCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resolved: false,
      image: null,
      noThumb: true,
      loaded: false,
    }
  }

  componentDidMount() {
    this.fetchInfo()
    this.fetchThumbnail()
  }

  async fetchInfo() {
    console.log('fetch info')
    try {
      const tripId = this.props.id

      const res = await fetchTripInfo(tripId)
      const {
        audioLength,
        notesLength,
        photoLength,
        videoLength,
        latestUpdate,
      } = res.data
      console.log(latestUpdate)
      this.setState({
        audioLength,
        notesLength,
        photoLength,
        videoLength,
        latestUpdate,
        resolved: true,
      })
    } catch (e) {
      console.error(e)
    }
  }

  async fetchThumbnail() {
    try {
      const tripId = this.props.id
      const token = await getUserToken()
      const res = await axios.post(`${backendUrl}/crea/trip/thumb`, {
        token,
        tripId,
      })
      const { noThumb, base64 } = res.data
      console.log(noThumb)
      if (noThumb) {
        this.setState({ noThumb: true, loaded: true })
      } else this.setState({ noThumb: false, loaded: true, image: base64 })
    } catch (e) {
      console.error(e)
    }
  }

  extractDateFromTs(ts) {
    const split = ts.split('T'),
      date = split[0]
    return date
  }

  render() {
    if (this.state.resolved)
      return (
        <Link
          to={`/trip/details?trip=${this.props.id}`}
          className={style.card}
          onClick={() => this.setState({ redirect: true })}
        >
          <div className={style.imageWrapper}>
            {!this.state.loaded ? (
              <Spinner />
            ) : this.state.noThumb ? (
              <img className={style.noThumb} src={noThumb} alt="no thumbnail" />
            ) : (
              <img
                src={this.state.image}
                alt={`apercu de ${this.props.title}`}
              />
            )}
          </div>

          <div className={style.textWrapper}>
            <h3 className={style.title}>{this.props.title}</h3>
            <span className={style.date}>
              du {this.extractDateFromTs(this.props.startDate)} au{' '}
              {this.state.latestUpdate
                ? this.extractDateFromTs(this.state.latestUpdate)
                : new Date().toLocaleString()}
            </span>

            <div className={style.infoWrapper}>
              <span>
                <span className={style.numberBold}>
                  {this.state.photoLength}
                </span>{' '}
                photo{this.state.photoLength > 1 ? 's' : null}
              </span>
              <span>
                <span className={style.numberBold}>
                  {this.state.videoLength}
                </span>{' '}
                video{this.state.videoLength > 1 ? 's' : null}
              </span>
              <span>
                <span className={style.numberBold}>
                  {this.state.notesLength}
                </span>{' '}
                note{this.state.notesLength > 1 ? 's' : null}
              </span>
              <span>
                <span className={style.numberBold}>
                  {this.state.audioLength}
                </span>{' '}
                enregistrement{this.state.audioLength > 1 ? 's' : null}
              </span>
            </div>
          </div>
        </Link>
      )
    else return <div>loading</div>
  }
}
