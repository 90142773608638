import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { checkPersistent, getUserToken } from '../../helpers/constants'

export default class CheckLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      trip: false,
    }
  }

  componentDidMount() {
    if (checkPersistent() && getUserToken()) {
      this.setState({ trip: true })
    } else {
      this.setState({ login: true })
    }
  }

  render() {
    const { trip, login } = this.state
    return (
      <div>
        {trip ? <Redirect to="/trip" /> : null}
        {login ? <Redirect to="/auth/signin" /> : null}
      </div>
    )
  }
}
