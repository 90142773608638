import React, { Component } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'

import style from './Mapbox.module.css'
import mapStyle from '../../assets/mapStyle.json'
import markerIcon from '../../assets/svg/icons/marker.svg'

export default class Mapbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {},
    }
  }

  componentDidMount() {
    this.setState({
      viewport: {
        height: this.mapboxElement.clientHeight,
        width: this.mapboxElement.clientWidth,
        latitude: this.props.latitude,
        longitude: this.props.longitude,
        zoom: 8,
      },
    })
  }

  render() {
    const { markers, latitude, longitude, zoom } = this.props
    return (
      <div
        className={style.map}
        ref={mapboxElement => (this.mapboxElement = mapboxElement)}
      >
        <ReactMapGL
          {...this.state.viewport}
          style={{ width: '100%', heigth: '100%' }}
          onViewportChange={viewport => this.setState({ viewport })}
          zoom={zoom}
          mapStyle={mapStyle}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          {markers.map(marker => (
            <Marker latitude={marker[0]} longitude={marker[1]} key={marker[2]}>
              <img src={markerIcon} alt=" " />
            </Marker>
          ))}
        </ReactMapGL>
      </div>
    )
  }
}
