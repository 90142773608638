import React, { Component } from 'react'

import Mapbox from '../../global/Mapbox'
import Spinner from '../../global/Spinner'
import { deleteNote, fetchMedia } from '../../../helpers/constants'

import style from './MediaViewer.module.css'
import mainStyle from '../../../styles/main.module.css'
import closeIcon from '../../../assets/svg/icons/close.svg'
import deleteIcon from '../../../assets/svg/icons/trash.svg'
import shareIcon from '../../../assets/svg/icons/share.svg'
import leftArrow from '../../../assets/svg/icons/left_arrow.svg'
import rightArrow from '../../../assets/svg/icons/right_arrow.svg'

export default class MediaViewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      media: [],
      menuHidden: false,
      displayMap: false,
      displayOverlay: false,
      displayCalendar: false,
      delete: false,
      resolved: false,
      currentIndex: 0,
      control: false,
    }
  }

  componentDidMount() {
    const { initIndex, media } = this.props
    console.log('viewer mounted')
    this.fetchMultiple(media)
  }

  close(e) {
    e.stopPropagation()
    this.props.close()
    this.setState({ control: false })
  }

  componentWillUpdate(nextProps) {
    console.log(nextProps, this.props)
    if (nextProps.initIndex !== this.props.initIndex && !this.state.control) {
      this.setState({ currentIndex: nextProps.initIndex })
    }

    if (nextProps.media !== this.props.media) {
      this.fetchMultiple(nextProps.media)
    }
  }

  async fetchMultiple(images) {
    for (let key in images) {
      const currentImage = images[key]
      const { id, tripId, extension } = currentImage
      const base64 = await fetchMedia(id, tripId, extension)
      images[key].base64 = base64
    }

    console.log('resolved images ')
    console.log(images)

    this.setState({
      media: images,
      resolved: true,
      currentIndex: this.props.initIndex,
    })
  }

  async fetch(id, tripId, extension) {
    const base64 = await fetchMedia(id, tripId, extension)
    return base64
  }

  renderSelectedMedia(index) {
    const { media } = this.state
    const current = media[index]
    const { extension, base64 } = current
    if (extension === 'jpg') {
      return <img src={base64} alt=" " />
    } else return <video src={base64} controls />
  }

  changeSelected(direction) {
    const { currentIndex } = this.state
    switch (direction) {
      case 'left': {
        if (currentIndex === 0) {
          this.setState({
            control: true,
            currentIndex: this.state.media.length - 1,
          })
        } else {
          this.setState({ control: true, currentIndex: currentIndex - 1 })
        }
        break
      }
      case 'right': {
        if (currentIndex === this.state.media.length - 1) {
          this.setState({ control: true, currentIndex: 0 })
        } else {
          this.setState({ control: true, currentIndex: currentIndex + 1 })
        }
        break
      }
      default: {
        console.log('switch default')
      }
    }
  }

  async delete(e) {
    const { media, currentIndex } = this.state
    //find current id
    const currentMedia = media[currentIndex]
    console.log(currentMedia)

    //display validation modal
    this.setState({ delete: currentMedia })
  }

  async submitDelete(id) {
    try {
      const deleted = await deleteNote(id, 'media')
      console.log(deleted)
      this.changeSelected('right')
      this.props.reload()
      this.setState({ delete: false })
    } catch (e) {
      console.log(e)
    }
  }

  displayValidation(media) {
    return (
      <div className={style.deleteValidation}>
        <div className={style.deleteCard}>
          <span>
            Supprimer cette {media.extension === 'jpg' ? 'photo' : 'vidéo'} ?
          </span>

          <div className={style.deleteBtnWrapper}>
            <button
              onClick={() => this.setState({ delete: false })}
              className={style.deleteNo}
            >
              Non
            </button>

            <button
              onClick={() => this.submitDelete(media.id)}
              className={style.deleteYes}
            >
              Oui
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { visible } = this.props

    if (visible) {
      return (
        <div className={style.wrapper}>
          <div className={`${style.column} ${style.left}`}>
            <button className={style.closeBtn} onClick={e => this.close(e)}>
              <img src={closeIcon} alt="Close Window" />
            </button>

            <button
              onClick={() => this.changeSelected('left')}
              className={`${style.arrow} ${style.leftArrow}`}
            >
              <img src={leftArrow} alt="Previous" />
            </button>
          </div>

          <div className={style.viewer}>
            {this.state.resolved ? (
              this.renderSelectedMedia(this.state.currentIndex)
            ) : (
              <Spinner />
            )}

            {this.state.delete
              ? this.displayValidation(this.state.delete)
              : null}
          </div>

          <div className={`${style.column} ${style.right}`}>
            <button
              onClick={() => this.changeSelected('right')}
              className={`${style.arrow} ${style.rightArrow}`}
            >
              <img src={rightArrow} alt="Next" />
            </button>

            <button
              onClick={e => this.delete(e)}
              className={`${style.sideIcon} ${style.delete}`}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          </div>
        </div>
      )
    } else return <div></div>
  }
}
