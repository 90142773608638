import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'

import { backendUrl } from '../../helpers/constants'
import style from './Auth.module.css'
import mainStyle from '../../styles/main.module.css'

export default class ForgottenPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false,
      error: false,
      msg: null,
      redirect: false,
      finished: false,
    }
  }

  submit = async event => {
    event.preventDefault()
    const { email } = this.state
    if (email) {
      this.setState({ loading: true })
      try {
        await axios.post(`${backendUrl}/auth/reset-password`, { email })
        this.setState({ error: false, finished: true })
      } catch (error) {
        console.error(error)
      }
    } else
      this.setState({
        error: true,
        msg: 'On dirait que vous n’avez pas renseingné votre email…',
      })
  }

  DisplayError = () => {
    if (this.state.error) {
      return <span className={style.error}>{this.state.msg}</span>
    } else {
      return null
    }
  }

  render() {
    if (this.state.finished) {
      return (
        <div className={style.mainWrapper}>
          <h1 className={style.title}>Mot de passe oublié ?</h1>
          <span className={style.span}>
            Pas d'inquiétude, on se charge de tout
          </span>

          <p className={style.subbutton}>
            Si cet email est dans notre base de données, vous allez bientôt
            recevoir un mail pour réinitialiser votre mot de passe !
          </p>

          <button
            className={mainStyle.bigPink}
            onClick={() =>
              this.setState({
                email: '',
                loading: false,
                error: false,
                msg: null,
                redirect: false,
                finished: false,
              })
            }
          >
            Je peux recommencer ?
          </button>
          <button
            className={mainStyle.bigPink}
            onClick={() => this.props.history.push('/auth/signin')}
          >
            Retour au login !
          </button>
        </div>
      )
    } else {
      return (
        <div className={style.mainWrapper}>
          <h1 className={style.title}>Mot de passe oublié ?</h1>
          <span className={style.span}>
            Pas d'inquiétude, on se charge de tout
          </span>

          <form onSubmit={this.submit}>
            <div className={style.inputWrapper}>
              <input
                className={style.input}
                onChange={event => this.setState({ email: event.target.value })}
                type="email"
                name="email"
                placeholder="E-mail"
              />
              <this.DisplayError />
            </div>

            <div className={style.wrapper}>
              <input
                type="submit"
                className={mainStyle.bigPink}
                value="Valider"
              />
              <Link className={style.subbutton} to="/auth/signin">
                C'est bon, je m'en souviens !
              </Link>
              <Link className={style.subbutton} to="/auth/signup">
                Vous n'avez pas encore de compte ?{' '}
                <span className={style.pinkText}>Inscrivez-vous</span>
              </Link>
            </div>
          </form>
        </div>
      )
    }
  }
}
