import axios from 'axios'

export const backendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://api.roadeo.app'

export const setUserToken = token => {
  localStorage.setItem('userToken', token)
}

export const setPersistent = status => {
  localStorage.setItem('persistent', status)
}

export const checkPersistent = () => localStorage.getItem('persistent')

export const getUserToken = () => localStorage.getItem('userToken')

export const deleteUserToken = () => {
  localStorage.removeItem('userToken')
}

export const fetchTrips = async () => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/crea/trip/all`, { token })
    const { all } = res.data
    return all
  } catch (e) {
    console.error(e)
  }
}

export const fetchTripNotes = async tripId => {
  try {
    const JWT = await getUserToken()
    const res = await axios.post(`${backendUrl}/crea/trip/fetch`, {
      tripId,
      JWT,
    })
    return res.data.trip
  } catch (e) {
    console.error(e)
  }
}

export const fetchTripInfo = async tripId => {
  try {
    return await axios.post(`${backendUrl}/crea/trip/info`, {
      tripId,
      token: getUserToken(),
    })
  } catch (e) {
    console.error(e)
  }
}

export const fetchMedia = async (id, tripId, extension) => {
  try {
    const res = await axios.get(
      `${backendUrl}/fetch/media?id=${id}&tripId=${tripId}&ext=${extension}`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const updateNoteText = async (id, text) => {
  try {
    const JWT = await getUserToken()
    const res = await axios.post(`${backendUrl}/crea/note/update/text`, {
      JWT,
      id,
      text,
    })
    const { updated } = res.data
    return updated
  } catch (e) {
    console.error(e)
  }
}

export const updateNoteColor = async (id, color) => {
  try {
    const JWT = await getUserToken()
    const res = await axios.post(`${backendUrl}/crea/note/update/color`, {
      JWT,
      id,
      color,
    })
    const { updated } = res.data
    return updated
  } catch (e) {
    console.error(e)
  }
}

export const deleteNote = async (id, type) => {
  try {
    const JWT = await getUserToken()
    const res = await axios.post(`${backendUrl}/crea/note/delete`, {
      JWT,
      id,
      type,
    })
    console.log(res)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const getUserInfo = async () => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/profile/info/get`, { token })
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getUserProfilePicKey = async () => {
  try {
    const userInfo = await getUserInfo()
    const { profile_picture } = userInfo
    return profile_picture
  } catch (e) {
    console.log(e)
  }
}

export const updateUserInfo = async (name, email, age, bio, city) => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/profile/info/update`, {
      token,
      email,
      age,
      bio,
      city,
      name,
    })
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const changeUserSettings = async (notifications, newsletter) => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/profile/settings/update`, {
      token,
      notifications,
      newsletter,
    })
    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getUserSettings = async () => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/profile/settings/get`, {
      token,
    })
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const changePwd = async (oldPwd, newPwd) => {
  try {
    const token = await getUserToken()
    const res = await axios.post(`${backendUrl}/profile/password-change`, {
      token,
      oldPwd,
      newPwd,
    })
    console.log(res.data)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const uploadProfilePic = async (base64, type) => {
  try {
    const token = await getUserToken()
    const extension = type === 'image/jpeg' ? 'jpg' : 'png'
    console.log(extension)
    const res = await axios.post(`${backendUrl}/profile/pic`, {
      token,
      base64,
      extension,
      type,
    })
    console.log(res)
    return res.data
  } catch (e) {
    console.error(e)
  }
}
