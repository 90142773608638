import React, { Component } from 'react'

import { uploadProfilePic, backendUrl } from '../../helpers/constants'

import style from './ProfilePic.module.css'
import noPic from '../../assets/svg/icons/profile_round.svg'
import edit from '../../assets/svg/icons/edit.svg'

export default class ProfilePic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      upload: false,
      profilePicUrl: null,
    }
  }

  componentDidMount() {
    const { pic } = this.props
    console.log(this.props)
    this.fetchPic(pic)
  }

  fetchPic(key) {
    if (key) {
      const url = `${backendUrl}/fetch/pic?key=${key}`
      console.log(url)
      this.setState({ profilePicUrl: url })
    }
  }

  componentWillUpdate(prevProps, nextState) {
    if (prevProps.pic !== this.props.pic) {
      this.fetchPic(prevProps.pic)
    }
  }

  async handleUpload(e) {
    e.preventDefault()
    const file = e.target.files[0]
    const { type } = file

    //read file
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        const { result } = reader
        const data = result.split(',')[1]
        //send to s3
        const response = await uploadProfilePic(data, type)
        console.log(response)
        this.fetchPic(response)
      }
      reader.onerror = error => console.log(error)
    }
  }

  render() {
    const { profilePicUrl } = this.state
    return (
      <div className={style.wrapper}>
        <div className={style.picWrapper}>
          <img
            className={style.pic}
            src={profilePicUrl ? profilePicUrl : noPic}
            alt="Profile Pic"
          />
        </div>
        <label className={style.editPic}>
          <div className={style.iconWrapper}>
            <img src={edit} alt="Change Profile Pic" />
          </div>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={e => this.handleUpload(e)}
          />
        </label>
      </div>
    )
  }
}
