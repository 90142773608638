import React, { Component } from 'react'

import TextDetails from './TextDetails'

import style from './TextCard.module.css'
import mainStyle from '../../../styles/main.module.css'
import markerIcon from '../../../assets/svg/icons/marker.svg'

export default class TextCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }

    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    console.log("I'm here bitch")
    if (!this.state.show) {
      this.setState({ show: true })
    }
  }

  closeModal(status) {
    console.log('close modal', status)
    // console.log(this.state);
    // console.log(this);
    this.setState({ show: status }, () => {
      // console.log('callback du setState', this.state)
    })
  }

  render() {
    return (
      <div
        className={`${style.card} ${this.props.color}`}
        onClick={() => this.openModal()}
      >
        <h4 className={style.location}>
          {this.props.icon ? (
            <img src={markerIcon} className={style.icon} alt=" " />
          ) : null}
          {this.props.locationName}
        </h4>
        <span className={style.date}>{this.props.date}</span>
        <p className={style.text}>{this.props.text}</p>

        {this.state.show ? (
          <div className={mainStyle.modalWrapper}>
            <TextDetails
              {...this.props}
              show={this.state.show}
              reload={this.props.reload}
              closeModal={this.closeModal}
            />
          </div>
        ) : null}
      </div>
    )
  }
}
