import React, { Component } from 'react'
import Spinner from './Spinner'

import style from './LoadingPage.module.css'

export default class LoadingPage extends Component {
  render() {
    return (
      <div className={style.wrapper}>
        <Spinner />
      </div>
    )
  }
}
