import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios'

import {
  backendUrl,
  setUserToken,
  setPersistent,
} from '../../helpers/constants'
import style from './Auth.module.css'
import mainStyle from '../../styles/main.module.css'

export default class SignIn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
      persistent: false,
      error: false,
      msg: null,
      redirect: false,
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  storeCredentials(token) {
    localStorage.setItem('userToken', token)
  }

  async submit() {
    const { email, password, persistent } = this.state
    if (email && password) {
      this.setState({ loading: true })
      try {
        const res = await axios.post(`${backendUrl}/auth/login`, {
          email,
          password,
        })
        const { message, token } = res.data

        if (!token) {
          this.setState({ error: true, msg: message })
          return
        }

        if (token) {
          setUserToken(token)
        }

        if (persistent) {
          setPersistent(true)
        } else {
          setPersistent(false)
        }

        this.setState({ error: false, redirect: true })
      } catch (e) {
        console.error(e)
      }
    } else
      this.setState({
        error: true,
        msg: 'Merci de renseigner votre e-mail et votre mot de passe.',
      })
  }

  renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to="/trip" />
    }
  }

  render() {
    return (
      <div className={style.mainWrapper}>
        {this.renderRedirect()}
        <span className={style.span}>Se connecter</span>

        <div className={style.inputWrapper}>
          <input
            className={style.input}
            onChange={e => this.handleChange(e)}
            type="email"
            name="email"
            placeholder="E-mail"
          />
          <input
            className={style.input}
            onChange={e => this.handleChange(e)}
            type="password"
            name="password"
            placeholder="Mot de passe"
          />

          {this.state.error ? (
            <span className={style.error}>{this.state.msg}</span>
          ) : null}

          <div className={style.underInput}>
            <label className={mainStyle.smallText}>
              <input
                type="checkbox"
                name="persistent"
                onChange={e => this.handleChange(e)}
              />
              Se souvenir de moi
            </label>

            <Link className={mainStyle.smallText} to="/auth/forgot-password">
              Mot de passe oublié ?
            </Link>
          </div>
        </div>

        <div className={style.wrapper}>
          <button onClick={e => this.submit()} className={mainStyle.bigPink}>
            Valider
          </button>
          <Link className={style.subbutton} to="/auth/signup">
            Vous n'avez pas encore de compte ?{' '}
            <span className={style.pinkText}>Inscrivez-vous</span>
          </Link>
        </div>
      </div>
    )
  }
}
