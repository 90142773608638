import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { backendUrl, getUserProfilePicKey } from '../../helpers/constants'

import style from './Navbar.module.css'

import logo from '../../assets/img/logo.png'
import noPic from '../../assets/svg/icons/profile_round.svg'

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profilePicUrl: null,
    }
  }

  componentDidMount() {
    this.fetchPic()
  }

  async fetchPic() {
    const key = await getUserProfilePicKey()
    if (key) {
      const url = `${backendUrl}/fetch/pic?key=${key}`
      console.log(url)
      this.setState({ profilePicUrl: url })
    }
  }

  render() {
    return (
      <nav className={`${style.nav} ${style[this.props.style]}`}>
        <div className={style.left}>
          <img className={style.logo} src={logo} alt="roadeo logo" />

          <NavLink to="/trip">Mes carnets</NavLink>
          <NavLink to="/print">Imprimer un carnet</NavLink>
        </div>

        <div className={style.right}>
          <NavLink to="/profile" className={style.imgWrapper}>
            <img
              className={style.profilepic}
              src={this.state.profilePicUrl ? this.state.profilePicUrl : noPic}
              alt="profile "
            />
          </NavLink>
        </div>
      </nav>
    )
  }
}
