import React from 'react'
import { Switch, Route } from 'react-router-dom'

import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgottenPassword from './ForgottenPassword'
import ResetPassword from './ResetPassword'

import art from '../../assets/svg/auth.svg'

import style from './Frame.module.css'

const Frame = props => (
  <div className={style.wrapper}>
    <div className={style.divider}>
      <div className={style.left}>
        <Switch>
          <Route path="/auth/signin" component={SignIn} />
          <Route path="/auth/signup" component={SignUp} />
          <Route path="/auth/forgot-password" component={ForgottenPassword} />
          <Route path="/auth/reset-password" component={ResetPassword} />
        </Switch>
      </div>
      <div className={style.right}>
        <img src={art} alt="signup art" className={style.illu} />
      </div>
    </div>
  </div>
)

export default Frame
